.navigationHomeMobile {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;

  padding: 2rem 0;



  .cardNavigation {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.3rem;

    background-color: var(--primary-color);
    border-radius: 0.5rem;

    padding: 1rem;

    img {
      width: 50px;
      height: auto;
    }

    .divisor {
      border: 1px solid var(--multiborder);
      width: 100%;
    }

    p {
      color: var(--white);
      margin-top: 0.5rem;
    }
  }
}
