.bannerSlider {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    .content {
        width: 100%;

        img {
            width: 100%;
        }

        button {
            background: var(--background-color-button-carousel, #FDB82C);
            padding: 0;
            border: 0;

            svg {
                background: transparent;
                fill: var(--color-button);
                font-size: 2rem;
            }
        }
    }
}

@media only screen and (max-width: 992px) {
    .bannerSlider {
        display: block;
        height: auto;
    }
}
