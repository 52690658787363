.eventHome {
    width: 100%;
    max-height: 30rem;
    overflow-x: auto;

    h1 {
        position: sticky;
        top: 0;
        z-index: 10;

        display: flex;
        align-items: center;
        gap: 1rem;

        background: var(--background-color-notice);
        background-image: var(--event-list-gradient);
        border-radius: 0.5rem;

        color: var(--white);
        font-size: 1.7rem;

        padding: 1rem;

        svg {
            font-size: 2rem;
        }

        span {
            color: var(--yellow-color);
            font-size: 1.4rem;
        }

        .more {
            margin-left: auto;
            cursor: pointer;
            color: var(--color-more);

            svg {
                height: 1rem;
                width: auto;
            }
        }
    }

    .listNotices {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--event-list-gap);

        margin-top: 3rem;

        .notice {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 4rem;
            width: 100%;
            border-bottom: 1px solid var(--event-item-border-color);
            cursor: pointer;
            padding: 0.6rem 2rem;

            &:hover {
                background: var(--primary-color);

                border-radius: 0.5rem;
            }

            p {
                display: flex;
                align-items: center;
                gap: 1rem;
                padding: 0.6rem 0;

                color: var(--white);
                font-size: 1.4rem;

                svg {
                    color: var(--white);
                    font-size: 2rem;
                }
            }

            span {
                color: var(--color-more);
                cursor: pointer;
                font-size: 1.2rem;
            }
        }
    }
}
